import { FC, useRef, useCallback } from "react";
import { Status } from "../../data/types";
import { Alternative } from "../interactions/InputTypes";
import { useTranslation } from "react-i18next";
import { id } from "../utils";
import "./NACE.scss";
import { TextInput } from "../form/TextInput";
import { InListValidator } from "../form/validators/InListValidator";
import { RequiredValidator } from "../form/validators/RequiredValidator";

interface Props {
  status?: Status;
  onChange: (value: string, name: string) => void;
  selectedNaceCode: string;
  name: string;
  active: boolean;
  alternatives: Alternative<string>[];
}

export const NACE: FC<Props> = ({
  status = Status.DEFAULT,
  onChange,
  selectedNaceCode,
  name,
  active,
  alternatives,
}) => {
  const { t } = useTranslation();
  const identifier = useRef<string>(id());
  const validCodes = new Set(alternatives.map((alt) => alt.value));

  const change = useCallback((changeValue, changeName) => onChange(changeValue, changeName), [onChange]);

  return (
    <>
      <div className="nace-input">
        <TextInput
          onChange={change}
          label={t("The main business activity the company conduct")}
          value={selectedNaceCode}
          name={name}
          list={identifier.current}
          disabled={!active || status !== Status.SUCCESS}
          validators={[
            new RequiredValidator(t("NACE is required")),
            new InListValidator(validCodes, t("NACE code is not valid")),
          ]}
        />
        <datalist id={identifier.current}>
          {alternatives.map((opt) => (
            <option value={opt.value} key={opt.value}>
              {opt.text}
            </option>
          ))}
        </datalist>
      </div>
    </>
  );
};
