import { Article } from "../../data/dataTerminalPricing";
import { Language } from "../../i18n";

import { Associate } from "../associate/associateTypes";
import {
  BeneficialOwnerId,
  Cas,
  ContractId,
  Country,
  DocumentId,
  SigneeId,
  StoreId,
  TerminalId,
  UserId,
  UTCDate,
} from "../common/commonType";
import { ContractPricing } from "../pricing/pricingTypes";

export enum VatStatus {
  NOT_CHECKED = "NOT_CHECKED",
  NOT_VALID = "NOT_VALID",
  VALID = "VALID",
  VIES_ERROR = "VIES_ERROR",
}

export enum Validity {
  MISSING = "has-missing-information",
  PARTIAL = "has-partial-information",
  VALID = "has-required-information",
  DEFAULT = "",
}

export enum ContractFeature {
  ADVANCED_PRICING = "ADVANCED_PRICING",
  LUMA_CONTRACT = "LUMA_CONTRACT",
}

export enum RegonStatus {
  FOUND = "FOUND",
  NOT_FOUND = "NOT_FOUND",
}

export enum ContractType {
  INSTORE = "INSTORE",
  ECOMMERCE = "ECOMMERCE",
}

export interface Contract {
  contractId: ContractId;
  status: ContractStatus;
  created: string;
  lastUpdated?: string;
  completed?: string;
  currentSalesUser?: UserId;
  teamId?: string;
  country: Country;
  contractData: MainContractData;
  associates: Associate[];
  stores: Store2[];
  logins: PortalLogin[];
  bankAccount: BankAccount;
  contractPackage: ContractPackage; // TODO set optional
  contractPricing?: ContractPricing[];
  countryFields: CountryCustomField[];
  documents: ContractDocument[];
  enabledFeatures: ContractFeature[];
  contractType: ContractType[];
}

export interface MainContractData {
  cas: Cas;
  companyRegistrationId: string;
  companyName?: string;
  website?: string;
  vatNumber?: string;
  companyType?: string;
  mcc?: string;
  cardholderStatementText?: string; //max 21 char
  countryOfOrigin?: string;
  primaryAddress: Address;
  beneficialOwnerType?: BeneficialOwnerType;
  vatResult?: VatStatus;
  language: Language;
  acceptance?: boolean;
  regonNumber?: string;
  regonResult?: RegonStatus;
  contractType: ContractType[];
  selectIfCompanyOrParentIs: string;
  dateOfIncorporation: string;
  supervisedByRegulatoryAuth: string;
  areFundsRemittedToThirdParty: string;
  totalEstimateAnnualTurnover: string;
  businessModel: string;
  purposeOfRelationship: string[];
  intendedUseOfProduct: string[];
  applicableBusinessPractices: string[];
  mainBusinessActivity: string;
}

export enum ContractStatus {
  PENDING_SALES_INPUT = "PENDING_SALES_INPUT",
  MERCHANT_VERIFICATION = "MERCHANT_VERIFICATION",
  MERCHANT_SIGNATURES = "MERCHANT_SIGNATURES",
  SALES_VERIFICATION = "SALES_VERIFICATION",
  COMPLETE = "COMPLETE",
  ANONYMISED = "ANONYMISED",
}

export enum IdType {
  NATIONAL_ID_CARD = "NATIONAL_ID_CARD",
  PASSPORT = "PASSPORT",
}

export enum BeneficialOwnerType {
  SOLE_PROPRIETOR = "SOLE_PROPRIETOR",
  MULTPLE_OWNERS = "MULTPLE_OWNERS",
  NO_DIRECT_LISTED_COMPANY = "NO_DIRECT_LISTED_COMPANY",
  NO_DIRECT_NO_MAJORITY = "NO_DIRECT_NO_MAJORITY",
  FIDUCIAL_OR_TRUST = "FIDUCIAL_OR_TRUST",
  FOUNDATION_OR_OTHER = "FOUNDATION_OR_OTHER",
}

export interface Address {
  street?: string;
  postalCode?: string;
  city?: string;
  countryCode?: string;
  streetNumber?: string;
}

export interface Contact {
  saluation?: Saluation;
  phoneNumber?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  function?: string;
}

export interface IdCard {
  idType?: IdType;
  idDocumentNo?: string;
  dateOfBirth?: string; //yyyy-mm-dd
  placeOfBirth?: string;
  nationalityCountryCode?: string;
  idIssuedIn?: string; // ???
  idIssuedByAuthority?: string;
  idIssuedByCountryCode?: string;
  idIssuedOn?: string; //yyyy-mm-dd
  idExpiry?: string; //yyyy-mm-dd
  noExpiry: boolean;
}

export interface ContractSignee {
  id: SigneeId;
  cas: Cas;
  created: string;
  contractId: ContractId;
  confirmed?: UTCDate;
  confirmedBy?: string;
  documents: ContractDocument[];
  signed?: string; //datetime
  signature?: string; //base64 bild, kanske kladdigt att hela skiten kommer upp här
  idCard: IdCard;
  address: Address;
  contact: Contact;
}

export interface BeneficialOwner {
  id: BeneficialOwnerId;
  cas: Cas;
  created: string;
  contractId: ContractId;
  confirmed?: UTCDate;
  confirmedBy?: string;
  documents: ContractDocument[];
  designatedOwner: boolean;
  designatedFunction?: string;
  capitalStake?: number;
  votingRightsStake?: number;
  company?: BeneficialOwnerCompany;
  idCard: IdCard;
  address: Address;
  contact: Contact;
}

export interface BeneficialOwnerCompany {
  name: string;
  ownershipStake?: number;
  street?: string;
  postalCode?: string;
  city?: string;
  countryCode?: string;
}

export interface Terminal {
  id: TerminalId;
  storeId: StoreId;
  cas: Cas;
  terminalType: TerminalType;
  terminalClosure: string; //localtime HH24:MM
  tip: boolean;
  bookAfterReservation: boolean;
  articles?: Article[];
}

export type GenerateNewTerminalPayload = Pick<Terminal, "storeId" | "terminalType">;

export interface StoreOld {
  cas: Cas;
  storeId: StoreId;
  commercialName?: string;
  address: Address;
  contact: Contact;
  terminals: Terminal[];
}

export enum TerminalType {
  SOFT_POS = "SOFT_POS",
  DESK_3500 = "DESK_3500",
  MOVE_3500 = "MOVE_3500",
  LINK_2500 = "LINK_2500",
  SATURN = "SATURN",
  DESK_5000 = "DESK_5000",
  MOVE_5000 = "MOVE_5000",
  DX_8000 = "DX_8000",
  ECOMMERCE = "ECOMMERCE",
}

export enum Saluation {
  HE = "HE",
  SHE = "SHE",
}

export enum PackageId {
  SOFTPOS_BUNDLE = "SOFTPOS_BUNDLE",
  MOVE_BUNDLE = "MOVE_BUNDLE",
  DESK_BUNDLE = "DESK_BUNDLE",
}

export interface PortalLogin {
  id: number;
  created: string;
  contractId: ContractId;
  email: string;
  name: string;
}

export interface NewPortalLogin {
  email: string;
  name: string;
}

export enum BankAccountSource {
  BANK_STATEMENT = "BANK_STATEMENT",
  OFFICIAL_REGISTRY = "OFFICIAL_REGISTRY",
  PSD_LOOKUP = "PSD_LOOKUP",
}

export interface BankAccount {
  cas: Cas;
  iban?: string;
  bank?: string;
  bic?: string;
  accountHolder?: string;
  signedOff?: UTCDate;
  statementUploaded?: UTCDate;
  fileName?: string;
  source?: BankAccountSource;
  mimeType?: string;
}

export enum PricingStep {
  LIGHT = "LIGHT",
  STANDARD = "STANDARD",
  ADVANCED = "ADVANCED",
}

export interface ContractPackage {
  cas: Cas;
  packageId?: PackageId;
  pricingStep?: PricingStep;
  promotionMonths?: number; //integer
  cashlessPromotion?: boolean; //Används bara i Polen
}

export interface CountryCustomField {
  cas: Cas;
  name: string;
  value: string;
}

export enum DocumentType {
  ID_DOCUMENT = "ID_DOCUMENT",
  BANK_STATEMENT = "BANK_STATEMENT",
  BUSINESS_CERTIFICATE = "BUSINESS_CERTIFICATE",
  TAXI_LICENSE = "TAXI_LICENSE",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  PROOF_OF_ACCREDITATION = "PROOF_OF_ACCREDITATION",
  FINANCIAL_STATEMENT = "FINANCIAL_STATEMENT",
  SALES_CUSTOM_REQUEST = "SALES_CUSTOM_REQUEST",
  E_COMMERCE_SUPPLEMENT = "E_COMMERCE_SUPPLEMENT",
  COMPANY_FORMATION_ESTABLISHMENT_DOCUMENT = "COMPANY_FORMATION_ESTABLISHMENT_DOCUMENT",
  MERCHANT_APPLICATION_FORM = "MERCHANT_APPLICATION_FORM",
  REGULATORY_COMPLIANCE_DOCUMENT = "REGULATORY_COMPLIANCE_DOCUMENT",
  PROOF_OF_OWNERSHIP_AND_CONTROL = "PROOF_OF_OWNERSHIP_AND_CONTROL",
  PROOF_OF_BANK_ACCOUNT_DETAILS = "PROOF_OF_BANK_ACCOUNT_DETAILS",
  LEGAL_AUTHORIZATION_DOCUMENT = "LEGAL_AUTHORIZATION_DOCUMENT",
  OTHER_DOCUMENT = "OTHER_DOCUMENT",
  PROOF_OF_COUNTRY_OF_RESIDENCY = "PROOF_OF_COUNTRY_OF_RESIDENCY",
  FINANCIAL_INFORMATION_DOCUMENT = "FINANCIAL_INFORMATION_DOCUMENT",
}

export interface ContractDocument {
  id: DocumentId;
  documentType: DocumentType;
  // Used by sales to describe a generic document that the customer needs to upload. Should be used when documentType = SALES_CUSTOM_REQUEST
  documentDescription?: string;
  created?: string; //datetime
  uploaded?: string; //datetime
  fileSize?: number;
  fileName?: string;
  mimeType?: string;
  //If its confirmed it can't be deleted
  confirmed?: UTCDate;
  confirmedBy?: string;
  fileAvailable: boolean;
}

export enum MccRiskLevel {
  NONE = "NONE",
  RISK_APPROVAL = "RISK_APPROVAL",
  NOT_ALLOWED = "NOT_ALLOWED",
}

export interface MerchantCategory {
  code: string;
  name: string;
  note?: string;
  category: string;
  cardNotPresent: MccRiskLevel;
  faceToFace: MccRiskLevel;
}

export enum ECommerceType {
  WEBSITE = "WEBSITE",
  TEST_WEBSITE = "TEST_WEBSITE",
  PAYMENT_LINKS = "PAYMENT_LINKS",
}

interface BaseOutlet {
  storeId: StoreId;
  cas: Cas;
  commercialName: string;
  cardStatementName: string; // max 21 chars
  address: Address;
  contact: Contact;
  storePhone: string;
  terminals: Terminal[];
  wlProduct: string;
  maxTransactionValue: string;
  avgTransactionValue: string;
  estimateTransactionsYear: string;
  estimateTurnoverYear: string;
}

interface InstoreOutlet extends BaseOutlet {
  contractType: ContractType.INSTORE;
}

interface ECommerceOutlet extends BaseOutlet {
  contractType: ContractType.ECOMMERCE;
  ecommerceType: ECommerceType;
  ecommerceURL: string;
  ecommerceTestURL: string;
  ecommerceUsername: string;
  ecommercePassword: string;
  ecommerceTermsAndConditionsURL: string;
}

export type Store2<T extends ContractType = any> = T extends ContractType.INSTORE
  ? InstoreOutlet
  : T extends ContractType.ECOMMERCE
  ? ECommerceOutlet
  : never;

export enum PaymentType {
  CARD_NOT_PRESENT = "CARD_NOT_PRESENT",
  FACE_TO_FACE = "FACE_TO_FACE",
}

export interface MaceCategory {
  code: string;
  name: string;
  description: string;
  category: string;
}
