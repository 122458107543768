import { useRecoilState } from "recoil";
import { FieldSet } from "../../components/fieldSet/FieldSet";
import { Toggle } from "../../components/interactions/Toggle/Toggle";
import { Link } from "../../components/links/Link";
import { featureState } from "../../state/featureState";
import { Feature, Store, STORE_KEY } from "../../Store";
import { PageStripped } from "../Page/PageStripped";
import "./FeatureTogglePage.scss";

export const FEATURE_TOGGLE_PAGE = "/secret/features";

export const FeatureTogglePage: React.FunctionComponent = () => {
  const [features, setFeatures] = useRecoilState(featureState);

  return (
    <PageStripped className="feature-toggle-page" title="Enable features">
      <section>
        <article>
          <div className="landscape-wrapper">
            <FieldSet header="Features" className="m-y-30">
              <div className="columns m-bottom-20">
                <div>
                  <label>Advanced pricing</label>
                </div>
                <div>
                  <Toggle
                    className="mini"
                    selected={features[Feature.ADVANCED_PRICING] ?? false}
                    onChange={(value: boolean) => {
                      setFeatures({
                        ...features,
                        [Feature.ADVANCED_PRICING]: value,
                      });
                      Store.setValue(STORE_KEY.STORE_FEATURES, {
                        ...features,
                        [Feature.ADVANCED_PRICING]: value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="columns  m-bottom-20">
                <div>
                  <label>E-commerce pricing</label>
                </div>
                <div>
                  <Toggle
                    className="mini"
                    selected={features[Feature.E_COMMERCE] ?? false}
                    onChange={(value: boolean) => {
                      setFeatures({
                        ...features,
                        [Feature.E_COMMERCE]: value,
                      });
                      Store.setValue(STORE_KEY.STORE_FEATURES, {
                        ...features,
                        [Feature.E_COMMERCE]: value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="columns  m-bottom-20">
                <div>
                  <label>Luma Contract</label>
                </div>
                <div>
                  <Toggle
                    className="mini"
                    selected={features[Feature.LUMA] ?? false}
                    onChange={(value: boolean) => {
                      setFeatures({
                        ...features,
                        [Feature.LUMA]: value,
                      });
                      Store.setValue(STORE_KEY.STORE_FEATURES, {
                        ...features,
                        [Feature.LUMA]: value,
                      });
                    }}
                  />
                </div>
              </div>
            </FieldSet>
            <Link link="/">Back to front</Link>
          </div>
        </article>
      </section>
    </PageStripped>
  );
};
