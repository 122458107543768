import { Filter } from "../Store";
import { data } from "./data";
import { ConfirmStatus, LookupMainBusiness } from "./dataConfirm";
import { BeneficialOwnerType, MaceCategory, Store2 } from "../model/contract/contractType";
import {
  Cas,
  ContractId,
  CorrelationId,
  DocumentId,
  StoreId,
  TeamId,
  TerminalId,
  UserId,
  UTCDate,
} from "../model/common/commonType";
import {
  Address,
  BankAccount,
  Contact,
  Contract,
  ContractDocument,
  ContractFeature,
  ContractPackage,
  ContractStatus,
  ContractType,
  MainContractData,
  MerchantCategory,
  NewPortalLogin,
  PortalLogin,
  TerminalType,
  VatStatus,
} from "../model/contract/contractType";
import { FilterUtils } from "../model/Filter";
import { Page, SortDirection } from "../model/Page";
import { LumaRequirementResponse } from "../state/lumaRequirementsState";

export interface ContractStatusResponse {
  status: Record<ContractStatus, number>;
}

export interface ContractListResponse {
  contractId: ContractId;
  created: UTCDate;
  lastUpdated: UTCDate;
  status: ContractStatus;
  companyRegistrationId: string;
  companyName?: string;
  currentSalesId: string;
  currentSalesName: string;
  onboardingCompleted?: UTCDate;
  contractType: ContractType[];
}

export interface AdminContractListResponse {
  contractId: ContractId;
  created: UTCDate;
  lastUpdated: UTCDate;
  status: ContractStatus;
  companyRegistrationId: string;
  companyName?: string;
  currentSalesName: string;
  salesUser: UserId;
  salesTeam: string;
  teamId: TeamId;
  onboardingCompleted?: UTCDate;
}

export interface NewContractResponse {
  contractId: ContractId;
  status: ContractCreationStatus;
}

export enum ContractCreationStatus {
  NEW = "NEW",
  RESUMED = "RESUMED",
  ALREADY_BOARDED = "ALREADY_BOARDED",
  BLOCKED = "BLOCKED",
  OTHER_TEAM = "OTHER_TEAM",
}

export interface SaveContractResponse {
  cas: Cas;
}

export interface SaveTerminal {
  id?: TerminalId;
  terminalType: TerminalType;
  terminalClosure?: string; //HH24:MM
  tip?: boolean;
  bookAfterReservation?: boolean;
}

export interface SaveNewStoreRequest {
  cas: Cas;
  commercialName: string;
  contact: Contact;
  address: Address;
  terminals: SaveTerminal[];
  cardStatementName?: string;
  contractType: ContractType;
}

export interface BicLookup {
  success: boolean;
  bic: string;
  bank: string;
}

export interface BankAccountSuggestion {
  iban: string;
  bank: string;
}

export interface CompanyLookupResult {
  result: LookupResult;
  vatResult: VatStatus;
}

interface LookupResult {
  contractId?: string;
  status: ContractCreationStatus;
}

export interface LookupPollResult {
  contractId?: string;
  status: LookupStatus;
  companyName?: string;
  companyLookupDone?: boolean;
  vatLookupDone?: boolean;
  bankAccountsAvailable?: boolean;
}

export enum LookupStatus {
  PENDING = "PENDING",
  FAILED = "FAILED",
  NOT_FOUND = "NOT_FOUND",
  NOT_ACTIVE = "NOT_ACTIVE",
  COMPLETE = "COMPLETE",
}

export enum CorrelationIdLookupStatus {
  NEW = "NEW",
  SALES = "SALES",
  MERCHANT = "MERCHANT",
  COMPLETE = "COMPLETE",
}

export interface CorrelationIdLookupResponse {
  contractId?: ContractId;
  status: CorrelationIdLookupStatus;
}

export interface ExistingMerchantData {
  created: UTCDate;
  mcc: number;
  mid: string;
  iban: string;
}

export enum CreateContractSalesForceError {
  CONTRACT_ALREADY_STARTED = "CONTRACT_ALREADY_STARTED",
  NO_CASE_FOUND = "NO_CASE_FOUND",
  PRIMARY_CONTACT_NOT_VALID = "PRIMARY_CONTACT_NOT_VALID",
  COUNTRY_NOT_VALID = "COUNTRY_NOT_VALID",
  ORGANIZATION_NUMBER_NOT_VALID = "ORGANIZATION_NUMBER_NOT_VALID",
  LEGAL_ENTITY_TYPE_NOT_ALLOWED = "LEGAL_ENTITY_TYPE_NOT_ALLOWED",
  COMPANY_NOT_ACTIVE = "COMPANY_NOT_ACTIVE",
  EMAIL_MISSING = "EMAIL_MISSING",

  CASE_COUNTRY_NOT_SAME_AS_WLX_USER = "CASE_COUNTRY_NOT_SAME_AS_WLX_USER",
  CASE_EMAIL_NOT_THE_SAME_AS_WLX_USER = "CASE_EMAIL_NOT_THE_SAME_AS_WLX_USER",
  COMPANY_ID_ALREADY_IN_USE = "COMPANY_ID_ALREADY_IN_USE",
}

export enum CreateContractError {
  COMPANY_DATA_NOT_AVAILABLE = "COMPANY_DATA_NOT_AVAILABLE",
}

export interface CreateContractResponse {
  contractId: ContractId;
  companyName?: string;
  companyRegistrationId?: string;
  registryLookupFound?: boolean;
  vatLookupFound?: boolean;
  bankAccountsAvailable?: boolean;

  error: CreateContractSalesForceError | CreateContractError;
}

export const CAS_ERROR_CODE = 409;
export const CLAIM_ERROR_CODE = 423;
export const SERVER_ERROR_CODE = 500;

export const dataContracts = {
  newContractWithCorrelationId: (correlationId: CorrelationId) =>
    data.post<CreateContractResponse>(`/api/sales/contracts/new-contract-sf?correlationId=${correlationId}`),

  companyLookupByWithCorrelationId: (correlationId: CorrelationId) =>
    data.get<CorrelationIdLookupResponse>(
      `/api/sales/contracts/salesforce/status?correlationId=${correlationId}`
    ),

  contractLookup: (
    companyRegistrationId: string,
    enabledFeatures: ContractFeature[],
    contractType: ContractType[]
  ) =>
    data.post<LookupResult>("/api/sales/contracts/lookup", {
      companyRegistrationId,
      enabledFeatures,
      contractType,
    }),

  contractLookupPoll: (companyRegistrationId: string) =>
    data.get<LookupPollResult>(
      `/api/sales/contracts/lookup?companyRegistrationId=${companyRegistrationId}`,
      true
    ),

  newContract: (
    companyRegistrationId: string,
    contractType: ContractType[],
    enabledFeatures?: ContractFeature[]
  ) =>
    data.post<NewContractResponse>("/api/sales/contracts/new-contract", {
      companyRegistrationId,
      enabledFeatures,
      contractType,
    }),

  loadContractsListing: (filter: Filter) => {
    const query = FilterUtils.getQueryFromFilter(filter);
    return data.get<Page<ContractListResponse>>(`/api/sales/contracts${query}`, true);
  },

  loadAdminContractsListing: (
    page: number,
    size: number,
    sortField = "created",
    sortDirection = "DESC" as SortDirection,
    search = ""
  ) => {
    return data.get<Page<AdminContractListResponse>>(
      `/api/admin/contracts?page=${page}&size=${size}&sortField=${sortField}&sortDirection=${sortDirection}&search=${search}`
    );
  },

  loadContract: (contractId: ContractId, force = false) =>
    data.get<Contract>(`/api/sales/contracts/${contractId}`, force),

  loadDocuments: (contractId: ContractId) =>
    data.get<ContractDocument[]>(`/api/sales/contracts/${contractId}/documents`, true),

  deleteContract: (contractId: ContractId) => data.delete(`/api/sales/contracts/${contractId}`),

  claimContract: (contractId: ContractId) => data.post(`/api/sales/contracts/${contractId}/claim`),

  //Kastar en 423 LOCKED om man inte har claimat
  saveMainContractData: (contractId: ContractId, request: MainContractData) =>
    data.post<SaveContractResponse>(`/api/sales/contracts/${contractId}`, request),

  loadContractsStatus: () => data.get<ContractStatusResponse>("/api/sales/contracts/status", true),

  addPortalLogin: (contractId: ContractId, request: NewPortalLogin) =>
    data.post<PortalLogin[]>(`/api/sales/contracts/${contractId}/login`, request),

  updatePortalLogin: (contractId: ContractId, request: PortalLogin, portalId: number) =>
    data.post<PortalLogin[]>(`/api/sales/contracts/${contractId}/login/${portalId}`, request),

  removePortalLogin: (contractId: ContractId, portalId: number) =>
    data.delete<PortalLogin[]>(`/api/sales/contracts/${contractId}/login/${portalId}`),

  updatePackage: (contractId: ContractId, request: ContractPackage) =>
    data.post<ContractPackage>(`/api/sales/contracts/${contractId}/package`, request),

  validateVat: (contractId: ContractId, vatNumber: string) =>
    data.post<{ valid: boolean; result: VatStatus }>(`/api/sales/externa/vies/${contractId}`, {
      vatNumber,
    }),

  updateContractWithLocalData: (contractId: ContractId) =>
    data.post<CompanyLookupResult>(`/api/sales/external/${contractId}`),

  //Den här kommer att cachea med en etag sen
  loadMcc: () => data.get<MerchantCategory[]>(`/api/sales/mcc`),
  loadMace: () => data.get<MaceCategory[]>(`/api/sales/mace`),

  saveStore: (contractId: ContractId, request: SaveNewStoreRequest, storeId?: StoreId) => {
    const uri = `/api/sales/contracts/${contractId}/store` + (storeId ? `/${storeId}` : ``);

    return data.post<Store2[]>(uri, request);
  },

  deleteStore: (contractId: ContractId, storeId: StoreId) => {
    return data.delete<Store2[]>(`/api/sales/contracts/${contractId}/store/${storeId}`);
  },

  saveBankAccount: (contractId: ContractId, bankAccount: BankAccount) =>
    data.post<BankAccount>(`/api/sales/contracts/${contractId}/bank`, {
      cas: bankAccount.cas,
      bank: bankAccount.bank,
      iban: bankAccount.iban,
      bic: bankAccount.bic,
      accountHolder: bankAccount.accountHolder,
    }),

  lookupBicAndBank: (iban: string, contractId: ContractId) => {
    return data.post<BicLookup>(`/api/external/iban`, {
      iban,
      contractId,
    });
  },

  registryBankLookup: (contractId: ContractId) =>
    data.get<BankAccountSuggestion[]>(`/api/sales/external/${contractId}/bank-accounts`),

  finalizeAndSendForConfirmation: (contractId: ContractId) =>
    data.post<ConfirmStatus>(`/api/sales/merchant/${contractId}/confirmation`),

  deleteAllTerminalsForStore: (contractId: ContractId, storeId: StoreId) => {
    return data.delete(`/api/sales/contracts/${contractId}/store/${storeId}/delete-terminals`);
  },

  deleteEcommerceDocument: (contractId: ContractId, documentId: DocumentId) => {
    return data.delete<ContractDocument[]>(
      `/api/sales/contracts/${contractId}/documents/e-commerce/${documentId}`
    );
  },

  viewContractDocument: (contractId: ContractId, documentId: DocumentId) => {
    return data.get(`/api/sales/contracts/${contractId}/documents/${documentId}`);
  },

  lumaLookupQuery: (contractId: ContractId, id: string) => {
    return data.get<LookupMainBusiness>(`/api/sales/luma/${contractId}/lookup/${id}`);
  },

  getLumaRequirements: (contractId: ContractId, companyType: BeneficialOwnerType) => {
    return data.get<LumaRequirementResponse[]>(`/api/sales/luma/${contractId}/requirements/${companyType}`);
  },
};
